
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import { ResourcesLocation } from '@/types/ordinarySeedling'
import { drawPolygon } from '@/utils/formatData'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'MapLocation'
})
export default class MapLocation extends Vue {
  // 是否有数量输入框
  @Prop({ default: false }) private numberInput?: boolean
  // 地图点是否可以改变(用于详情页面)
  @Prop({ default: true }) private isChange?: boolean
  // 项目id(获取电子围栏)
  @Prop() private projectId?: [number, number]
  // 回显时点的数据
  @Prop() private resourcesLocationList?: ResourcesLocation[]
  // 图标
  @Prop() private icon!: string
  // 打点资源名称(详情必传)
  @Prop({ default: '' }) private name?: string
  // 打点资源单位
  @Prop({ default: '' }) private unit?: string

  private map: AMap.Map | any = null
  private marker: AMap.Marker | any = null
  private infoWindow: AMap.InfoWindow | any = null
  private amount: number | null = null
  private amountShow = false
  private ploygons: Array<AMap.Polygon> = []
  private showDialog = false
  private locInfo: { lat: string; lng: string } = {
    lng: '',
    lat: ''
  }

  private locRules = {
    lng: [{ required: true, message: '请输入经度' }],
    lat: [{ required: true, message: '请输入纬度' }]
  }

  @Watch('resourcesLocationList')
  locationChange () { // 回显时
    this.map && this.markerListAdd()
  }

  @Watch('projectId')
  projectChange () { // 设置地图中心随项目移动
    this.getProjectLocation()
  }

  created () {
    this.loadMap()
  }

  destroyed (): void {
    if (this.map.getAllOverlays('marker').length > 0) {
      this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
        item.off('click', () => {
          // 清除marker事件
        })
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    if (this.map) {
      this.map.off('click', null)
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.map && this.ploygons && this.map.remove(this.ploygons)
      this.ploygons = drawPolygon(this.map, res.projectLocation, null, (e: any) => {
        this.isChange && this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.map = new AMap.Map('map', {
        zoom: 11,
        doubleClickZoom: false
      })
      this.projectId && this.getProjectLocation() // 获取电子围栏
      this.markerListAdd()
      this.isChange && this.map.on('click', (e: any) => {
        this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  handleClose (done: Function) {
    this.resetForm('locInfo')
    done()
  }

  onCancel () {
    this.resetForm('locInfo')
    this.showDialog = false
  }

  // 手动添加点
  onAdd (formName: string) {
    (this.$refs[formName] as ElForm).validate((valid) => {
      if (valid) {
        this.markerClick([Number(this.locInfo.lng), Number(this.locInfo.lat)])
        this.onCancel()
      } else {
        return false
      }
    })
  }

  resetForm (formName: string) {
    (this.$refs[formName] as ElForm).resetFields()
  }

  // 添加marker
  markerClick (position: [number, number]) {
    // 删除上一个点
    if (!this.numberInput && this.marker) {
      this.marker.setMap(null)
      this.marker = null
    }
    this.markerAdd([Number(position[0]), Number(position[1])], this.amount || 1)
    if (this.numberInput) {
      this.addSetLabel(this.amount || 1)
      this.clickMarker(this.marker)
      this.marker.on('click', (MapsEvent: any) => {
        if (this.infoWindow) {
          this.infoWindow.close() // 关闭信息窗
          this.infoWindow = null
        } else {
          this.clickMarker(MapsEvent.target)
        }
      })
    }
    this.markerChange()
  }

  markerAdd (position: [number, number], amount: number) {
    this.marker = new AMap.Marker({
      icon: new AMap.Icon({
        // 图标的取图地址
        image: this.icon
      }),
      offset: new AMap.Pixel(-20, -40),
      extData: {
        data: {
          amount: amount,
          longitude: Number(position[0]),
          latitude: Number(position[1])
        }
      },
      position: [Number(position[0]), Number(position[1])]
    })
    this.map.add(this.marker)
  }

  // 回显
  markerListAdd () {
    this.map && this.map.remove(this.map.getAllOverlays('marker'))
    this.resourcesLocationList && this.resourcesLocationList.map((item: ResourcesLocation) => {
      this.markerAdd([Number(item.longitude), Number(item.latitude)], item.amount)
      if (this.numberInput || this.name) {
        this.addSetLabel(item.amount)
      }
      this.isChange && this.marker.on('click', (MapsEvent: any) => {
        if (this.infoWindow) {
          this.infoWindow.close() // 关闭信息窗
          this.infoWindow = null
        } else {
          this.numberInput && this.clickMarker(MapsEvent.target)
        }
      })
    })
  }

  // marker被点击
  clickMarker (target: any) {
    this.amount = null
    this.marker = target
    this.amountShow = true
    this.$nextTick(() => {
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, // 使用自定义窗体
        content: (this.$refs.input as any),
        offset: new AMap.Pixel(0, -45)
      })
      this.amount = this.marker.getExtData().data.amount
      this.infoWindow.open(this.map, target.getPosition())
    })
  }

  // 保存
  save () {
    if (this.amount) {
      this.marker.getExtData().data.amount = this.amount
      this.addSetLabel(this.amount)
      this.infoWindow.close() // 关闭信息窗
      this.infoWindow = null
      this.markerChange()
    } else {
      this.$message.warning('请输入1~9999的整数')
    }
  }

  // 删除点
  deleteMarker () {
    this.marker.remove()
    this.infoWindow.close() // 关闭信息窗
    this.infoWindow = null
    this.markerChange()
  }

  // 添加数量文本窗
  addSetLabel (number: number) {
    const html = this.numberInput
      ? `<div class="infoWindow2">
        <p>数量<span>${number}</span></p>
      </div>`
      : `<div class="infoWindow2">
        <p>${this.name}</p>
      </div>`
    this.marker.setLabel({
      content: html, // 设置文本标注内容
      offset: new AMap.Pixel(0, -10),
      direction: 'top' // 设置文本标注方位
    })
  }

  // 返回数据
  markerChange () {
    const resourcesLocationList: ResourcesLocation[] = []
    this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
      resourcesLocationList.push(item.getExtData().data)
    })
    this.$emit('locationChange', resourcesLocationList)
  }

  amountChange (value: string) {
    if (value && Number(value) > 9999) {
      this.amount = Number(value.substr(0, 4))
    } else if (value) {
      this.amount = Number(value.replace(/[^0-9]/g, ''))
    }
  }
}
