
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Info, ResourcesLocation } from '@/types/dispatch'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { MapLocation, UploadFile }
})

export default class DispatchAdd extends Vue {
  private info: Info = {
    projectId: '',
    typeId: '',
    dispatchPosition: '',
    dispatchSdescribe: '',
    resourceList: [],
    longitude: '',
    latitude: ''
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    typeId: [
      { required: true, message: '请选择事件类型', trigger: 'change' }
    ],
    dispatchPosition: [
      { required: true, message: '请输入事件位置', trigger: ['change', 'blur'] }
    ],
    longitude: [
      { required: true, message: '请地图打点', trigger: 'change' }
    ],
    dispatchSdescribe: [
      { required: true, message: '请输入事件描述', trigger: 'change' }
    ]
  }

  private areaList = []
  private typeList = []

  private submitShow = false
  private resourcesLocationList: ResourcesLocation[] | null = null
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getTypeList()
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.manholeManage.selectManholeByManholeId, {
      manholeId: this.$route.params.id
    }).then(res => {
      this.info = res || {}
      if (res) {
        this.getAreaList(res.projectId)
        this.resourcesLocationList = [{
          amount: 1,
          longitude: res.longitude,
          latitude: res.latitude
        }]
      }
    })
  }

  // 事件类型
  getTypeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  getAreaList (projectId: string) {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId,
      notAllArea: '1'
    }).then(res => {
      this.areaList = res.list || []
    })
  }

  locationChange (list: ResourcesLocation[]) {
    if (list.length > 0) {
      this.info.longitude = list[0].longitude
      this.info.latitude = list[0].latitude
    }
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.dispatch.insertDispatch, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'dispatchList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
